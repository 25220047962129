<template>
  <module
    ref="module"
    id="newmasters"
    titleIcon="nc-icon nc-tv-2"
    :title="$t('helper_tradedassets')"
    :use-default-list="false"
  >
    <div slot="global">
      <!-- In progress -->
      <div v-if="inprogress" class="text-center">
        <h2>{{ inprogresstxt }}</h2>
        <i class="fa fa-cog fa-spin fa-huge fa-fw" style="color: #51bcda;"></i>
      </div>

      <div v-else class="row">

        <!-- Modal for Confirm Removal -->
        <modal :show.sync="modalconfirm" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">
            {{ helper_tradedassets }}
            {{ $t('completedtxt') }}?
          </h4>

          <div class="row">
            <div class="col-12">
              {{ $t('confirmtaskdone') }}
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button @click.prevent="confirmedTaskDone" link>{{ $t('yestxt') }}</p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalconfirm = false" link>{{ $t('notxt') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <!-- Modal for Traded Assets -->
        <modal :show.sync="modalassets" headerClasses="justify-content-center">
          <h4 slot="header" class="title title-up">{{ helper_tradedassets }}</h4>

          <div class="" v-if="alert_me_modal && alert_msg && !completed">
            <div class="alert alert-danger">
              <i class="fa fa-warning"></i>
              {{ alert_msg }}
            </div>
          </div>

          <div class="row" v-if="isFollower">
            <div class="col-12">
              <b>{{ $t('mastertxt') }} {{ $t('brokerservers') }}:</b>
              {{ mbroker_server }}
              ({{ master_platform.toUpperCase() }})
            </div>
          </div>

          <div class="row" v-if="isFollower">
            <div class="col-12">
              <b>{{ master_tradedassets }}:</b>
              {{ massets ? massets : $t('notavailable') }}
            </div>
          </div>

          <div class="row">&nbsp;</div>

          <div class="row">
            <div class="col-12">
              <label style="font-weight: bold">
                {{ isFollower ? helper_tradedassetsmatching : helper_tradedassets }}
              </label>
              <fg-input
                v-model="assets"
                addon-left-icon="fa fa-keyboard-o"
                :placeholder="isFollower ? helper_tradedassetsmatching : helper_tradedassets"
                type="text">
              </fg-input>
            </div>
          </div>

          <div class="row" v-if="isFollower">
            <div class="col-md-12">
              <label style="font-weight: bold">{{ helper_assetprefix }}</label>
              <fg-input
                v-model="assetPrefix"
                addon-left-icon="fa fa-keyboard-o"
                type="text"
                :placeholder="helper_assetprefix"></fg-input>
            </div>
          </div>

          <div class="row" v-if="isFollower">
            <div class="col-md-12">
              <label style="font-weight: bold">{{ helper_assetsuffix }}</label>
              <fg-input
                v-model="assetSuffix"
                addon-left-icon="fa fa-keyboard-o"
                type="text"
                :disabled="defaultSuffix"
                :placeholder="helper_assetsuffix"></fg-input>
            </div>
            <div class="col-md-12">
              <p-checkbox
                v-model="defaultSuffix"
                v-on:input="assetSuffix = ''">
                  <span style="color: black;">
                    {{ $t('usedefaultassetssuffix') }}
                  </span>
              </p-checkbox>
            </div>
          </div>

          <div class="row" v-if="isFollower">
            <div class="col-md-12">
              <label style="font-weight: bold">{{ helper_tradecomment }}</label>
              <fg-input
                v-model="tradeComment"
                addon-left-icon="fa fa-keyboard-o"
                type="text"
                :placeholder="helper_tradecomment"></fg-input>
            </div>
          </div>

          <div class="row" v-if="master.settings.assetsPairsToReceiveSignals == 'allButTheseAssetsPairsReceiveSignal'">
            <div class="col-md-12">
              <label style="font-weight: bold">{{ helper_allbuttheseassetspairsreceivesignal }}</label>
              <fg-input v-model="master.settings.allButTheseAssetsPairsReceiveSignal"
                        addon-left-icon="fa fa-keyboard-o"
                        disabled
                        type="text"></fg-input>
            </div>
          </div>

          <div class="row" v-if="master.settings.assetsPairsToReceiveSignals == 'onlyTheseAssetsPairsReceiveSignal'">
            <div class="col-md-12">
              <label style="font-weight: bold">{{ helper_onlytheseassetspairsreceivesignal }}</label>
              <fg-input v-model="master.settings.onlyTheseAssetsPairsReceiveSignal"
                        addon-left-icon="fa fa-keyboard-o"
                        disabled
                        type="text"></fg-input>
            </div>
          </div>

          <div class="row"
               v-if="master.settings.onlyTheseAssetsPairsReceiveSignal || master.settings.allButTheseAssetsPairsReceiveSignal">
            <div class="col-md-12 text-center">
              <a class="btn btn-info"
                 :href="'/admin_strategies/' + userid + '/up/' + sid + '/' + aid">
                {{ $t('paramaterchangetxt') }}
              </a>
            </div>
          </div>

          <div class="row" v-if="isFollower && assetsPairsToReceiveSignals && assetsPairsToReceiveSignals != 'all'">
            <div class="col-md-12" style="margin-top: 20px">
              <h6>{{ $t('settings') }}</h6>
            </div>
            <div class="col-md-12" v-if="allButTheseAssetsPairsReceiveSignal">
              <label style="font-weight: bold">{{ helper_allbuttheseassetspairsreceivesignal }}</label>
              <fg-input
                v-model="allButTheseAssetsPairsReceiveSignal"
                addon-left-icon="fa fa-keyboard-o"
                type="text"
                :placeholder="helper_allbuttheseassetspairsreceivesignal"></fg-input>
            </div>
            <div class="col-md-12" v-if="onlyTheseAssetsPairsReceiveSignal">
              <label style="font-weight: bold">{{ helper_onlytheseassetspairsreceivesignal }}</label>
              <fg-input
                v-model="onlyTheseAssetsPairsReceiveSignal"
                addon-left-icon="fa fa-keyboard-o"
                type="text"
                :placeholder="helper_onlytheseassetspairsreceivesignal"></fg-input>
            </div>
          </div>

          <div class="row">&nbsp;</div>

          <div class="row">
            <div class="col-12 text-center">
              <p-checkbox v-model="completed">
                {{ $t('completedtxt') }}?
              </p-checkbox>
            </div>
          </div>

          <template slot="footer">
            <div class="left-side">
              <p-button type="success" @click.prevent="confirmedDone" link>
                {{ $t('confirm') }}
              </p-button>
            </div>
            <div class="divider"></div>
            <div class="right-side">
              <p-button type="danger" v-on:click="modalassets = false" link>{{ $t('notxt') }}</p-button>
            </div>
          </template>
        </modal>
        <!-- End Modal -->

        <div class="col-12 text-center" v-if="alert_me">
          <div :class="alert_class" class="ml-2 mb-2" style="display: block;font-weight: bold;">
            {{ alert_msg }}
          </div>
        </div>

        <div class="col-md-12">
          <div class="row">

            <div class="col-sm-2">
              <label> {{ sortbyTxt }} </label>
              <el-select v-model="sortby.column" :placeholder="sortbyTxt"
                         v-on:change="sortByCol">
                <el-option class="select-default"
                           v-for="item in sortby.columns"
                           :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ perpageTxt }} </label>
              <el-select
                v-model="pagination.perPage" :placeholder="perpageTxt">
                <el-option class="select-default"
                           v-for="item in pagination.perPageOptions"
                           :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>

            <div class="col-sm-2">
              <label> {{ sortbyOrder }} </label>
              <el-select
                v-model="sortby.order" :placeholder="sortbyOrder"
                v-on:change="sortByCol">
                <el-option class="select-default"
                           v-for="item in sortby.orders"
                           :key="item.prop" :label="item.label" :value="item.prop">
                </el-option>
              </el-select>
            </div>


            <div class="col-sm-6">
              <div class="pull-right">
                <label> {{ searchtxt }}</label>
                <fg-input class="input-sm"
                          v-model="searchQuery"
                          addon-right-icon="nc-icon nc-zoom-split">
                </fg-input>
              </div>
            </div>
            <div class="col-sm-12 mt-2">
              <el-table class="table-striped"
                        :data="queriedData"
                        border
                        style="width: 100%">
                <el-table-column :label="actionlbl" min-width="250" width="250">
                  <div slot-scope="scope" class="text-center">
                    <div class="btn-group">
                      <el-tooltip :content="`${$t('task_completed')}`" placement="top">
                        <p-button type="primary"
                                  link
                                  v-on:click="done(`${scope.row.id}`, `${scope.row.strat_name}`)"
                                  v-if="scope.row.confirmed">
                          <i class="fa fa-check-square-o" style="font-size: x-large;"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('edit_trade_assets')}`" placement="top">
                        <p-button type="success"
                                  link
                                  v-on:click="modify(scope.row)"
                                  v-if="!scope.row.confirmed">
                          <i class="fa fa-pencil" style="font-size: x-large;"></i>
                        </p-button>
                      </el-tooltip>
                      <el-tooltip :content="`${$t('copy_pass')}`" placement="top">
                        <p-button type="danger" link v-on:click="showpass(`${scope.row.id}`, `${scope.row.password}`)"
                                  v-if="!scope.row.showingpass">
                          <i class="fa fa-key" style="font-size: x-large;"></i>
                        </p-button>
                      </el-tooltip>
                      <p-button type="success" link v-if="scope.row.showingpass">
                        <i class="fa fa-key" style="font-size: x-large;"></i>
                      </p-button>
                      <p-button type="primary" round icon link v-if="scope.row.acc_type == 'master'">M</p-button>
                      <p-button type="info" round icon link v-if="scope.row.acc_type !== 'master'">F</p-button>

                    </div>
                  </div>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('account')}`">
                  <template slot-scope="scope">
                    <span v-on:click="copyit(scope.row.account)" class="clickable">
                      {{ scope.row.account }} ({{ scope.row.platform.toUpperCase() }})
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('userid')}`">
                  <template slot-scope="scope">
                    {{ scope.row.userid }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('fullname')}`">
                  <template slot-scope="scope">
                    {{ scope.row.first_name }} {{ scope.row.last_name }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('strategytxt')}`">
                  <template slot-scope="scope">
                    {{ scope.row.strat_name }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('servertxt')}`">
                  <template slot-scope="scope">
                    <span v-on:click="copyit(scope.row.server)" class="clickable">
                      {{ scope.row.server }}
                    </span>
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('mattxt')}`">
                  <template slot-scope="scope">
                    {{ scope.row.location }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('publictxt')}`">
                  <template slot-scope="scope">
                    {{ scope.row.public }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('price')}`">
                  <template slot-scope="scope">
                    {{ scope.row.price }}
                  </template>
                </el-table-column>

                <el-table-column
                  :label="`${this.$t('date')}`">
                  <template slot-scope="scope">
                    {{ scope.row.entry_dt }}
                  </template>
                </el-table-column>


              </el-table>

              <input id="clipit" type="text" v-model="clipboard" style="display: none">

            </div>
            <div class="col-sm-6 pagination-info">
              <p class="category">Showing {{ from + 1 }} to {{ to }} of {{ total }} entries</p>
            </div>
            <div class="col-sm-6">
              <p-pagination class="pull-right"
                            v-model="pagination.currentPage"
                            :per-page="pagination.perPage"
                            :total="pagination.total">
              </p-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </module>
</template>
<script>
import Vue from 'vue'
import {Table, TableColumn, Select, Option} from 'element-ui'
import PPagination from 'src/components/UIComponents/Pagination.vue'
import {Button, Modal} from 'src/components/UIComponents'
import {Tooltip} from 'element-ui'

Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Select)
Vue.use(Option)
Vue.use(require('vue-moment'))

const user_data = JSON.parse(localStorage.getItem('user-info'));

let bills = [];
let timeoutcount = 1500;

export default {
  components: {
    PPagination, [Button.name]: Button, Modal,
    [Tooltip.name]: Tooltip
  },
  computed: {
    pagedData () {
      return this.tableData.slice(this.from, this.to)
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData () {
      if (!this.searchQuery) {
        this.pagination.total = this.tableData.length
        return this.pagedData
      }
      let result = this.tableData
        .filter((row) => {
          let isIncluded = false
          for (let key of this.propsToSearch) {
            //let rowValue = row[key].toString()
            let searchRegex = new RegExp(this.searchQuery, 'gi');
            //if (rowValue.includes && rowValue.includes(this.searchQuery.toLowerCase())) {
            if (row[key].toString().match(searchRegex) !== null) {
              isIncluded = true
            }
          }
          return isIncluded
        })
      this.pagination.total = result.length
      return result.slice(this.from, this.to)
    },
    to () {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from () {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total () {
      this.pagination.total = this.tableData.length
      return this.tableData.length
    }
  },
  data () {
    return {
      perpageTxt: this.$t('perpage'),
      sortbyTxt: this.$t('sortby'),
      sortbyOrder: this.$t('sortbyorder'),
      searchtxt: this.$t('searchtxt'),
      colSort: 'entry_dt',
      inprogress: true,
      actionlbl: this.$t('action'),
      alert_me: false,
      alert_msg: null,
      alert_class: 'text-danger',
      modalassets: false,
      helper_tradedassets: this.$t('helper_tradedassets'),
      alert_me_modal: false,
      massets: null,
      assets: null,
      completed: false,
      isFollower: false,
      assetPrefix: null,
      assetSuffix: null,
      tradeComment: null,
      allButTheseAssetsPairsReceiveSignal: null,
      onlyTheseAssetsPairsReceiveSignal: null,
      assetsPairsToReceiveSignals: null,
      master: {
        assetSuffix: null,
        settings: {},
      },
      defaultSuffix: true,
      helper_assetprefix: this.$t('helper_assetprefix'),
      helper_assetsuffix: this.$t('helper_assetsuffix'),
      helper_tradecomment: this.$t('helper_tradecomment'),
      msid: null,
      accid: null,
      uid: null,
      helper_tradedassetsmatching: this.$t('helper_tradedassetsmatching'),
      mbroker_server: null,
      master_platform: null,
      helper_allbuttheseassetspairsreceivesignal: this.$t('allbuttheseassetspairsreceivesignaltxt'),
      helper_onlytheseassetspairsreceivesignal: this.$t('onlytheseassetspairsreceivesignaltxt'),
      master_tradedassets: this.$t('mastertxt') +" "+ this.$t('helper_tradedassets'),
      inprogresstxt: this.$t('loadingtxt') +" "+ this.$t('newmasters') +". "+ this.$t('pleasewait'),
      sortby: {
        column: 'location',
        order: 'asc',
        orders: [
          {
            prop: 'asc',
            label: this.$t('ascending')
          },
          {
            prop: 'desc',
            label: this.$t('descending')
          }
        ],
        columns: [
          {
            prop: 'userid',
            label: this.$t('userid')
          },
          {
            prop: 'first_name',
            label: this.$t('firstname')
          },
          {
            prop: 'last_name',
            label: this.$t('lastname')
          },
          {
            prop: 'strat_name',
            label: this.$t('strategytxt')
          },
          {
            prop: 'account',
            label: this.$t('account')
          },
          {
            prop: 'server',
            label: this.$t('servertxt')
          },
          {
            prop: 'location',
            label: this.$t('mattxt')
          },
          {
            prop: 'public',
            label: this.$t('publictxt')
          },
          {
            prop: 'price',
            label: this.$t('price')
          },
          {
            prop: 'entry_dt',
            label: this.$t('date')
          }
        ]
      },
      pagination: {
        perPage: 50,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      clipboard: '',
      propsToSearch: ['userid', 'first_name', 'last_name', 'account', 'strat_name', 'location'],
      tableColumns: [
        {
          prop: 'userid',
          label: this.$t('userid')
        },
        {
          prop: 'first_name',
          label: this.$t('fullname'),
          formatter: function (row, column, value) {
            return `${row.first_name} ${row.last_name}`
          }
        },
        {
          prop: 'strat_name',
          label: this.$t('strategytxt')
        },
        {
          prop: 'server',
          label: this.$t('servertxt')
        },
        {
          prop: 'location',
          label: this.$t('mattxt')
        },
        {
          prop: 'public',
          label: this.$t('publictxt')
        },
        {
          prop: 'price',
          label: this.$t('price')
        },
        {
          prop: 'entry_dt',
          label: this.$t('date')
        }
      ],
      tableData: [],
      modalconfirm: false,
      passid: null,
      did: null,
      userid: '',
      sid: null,
      aid: null,
    }
  },
  methods: {
    copyit(value) {
      let cb = document.getElementById("clipit");
      cb.style.display = 'block';
      cb.value = value;
      cb.select();
      document.execCommand('copy');
      cb.style.display = 'none';
      this.$toast.success(`${value} Copied!`)
    },
    closeModalAlert() {
      this.modalconfirm = false;
      this.did = null;
    },
    completedTask() {
      this.closeModalAlert();
      this.$toast.success(this.$t('goodjob'))
      // Reload and close
      this.$refs.module.refreshList();
      this.changeLogsAssets().then(this.loadNewMasters, this.failop);
    },
    confirmedTaskDone() {
      this.$changeLogAssetDone(this.did, user_data.email).then(this.completedTask, this.failop);
    },
    confirmedDone() {
      if (this.completed) {
        const m = this.tableData.findIndex(t => t.id === this.did);
        if (m >= 0) {
          const mtr = this.tableData[m];
          this.tableData[m].confirmed = true;
          if (this.isFollower) {
            this.msid = mtr.strat_id;
            this.aid = mtr.acc_id;
            this.uid = mtr.userid;
            this.$updateAssetFollower({
              sid: mtr.strat_id,
              aid: mtr.acc_id,
              tradedAssets: this.assets,
              suffix: this.assetSuffix,
              prefix: this.assetPrefix,
              tradeComment: this.tradeComment,
              allButTheseAssetsPairsReceiveSignal: this.allButTheseAssetsPairsReceiveSignal,
              onlyTheseAssetsPairsReceiveSignal: this.onlyTheseAssetsPairsReceiveSignal,
              assetsPairsToReceiveSignals: this.assetsPairsToReceiveSignals,
              defaultSuffix: this.defaultSuffix,
            }).then(this.updatedAssets, this.failop);
          } else {
            this.$updateAsset(mtr.strat_id, this.assets)
              .then(this.updatedAssets, this.failop);
          }
        }
      } else {
        this.alert_me_modal = true;
        this.alert_msg = this.$t('pleaseconfirmdone');
        this.alert_class = 'text-danger';
      }
    },
    updatedAssets() {
      this.modalassets = false;
      this.alert_me_modal = false;
      this.alert_msg = null;
      this.alert_class = 'text-danger';

      this.assets = null;
      this.msid = null;
      this.aid = null;
      this.uid = null;
      this.completed = false;
    },
    done(id) {
      this.did = id;
      this.modalconfirm = true;
    },
    resetAlert() {
      this.alert_me = false;
      this.alert_msg = null;
      this.alert_class = 'text-danger';
    },
    modify(row) {
      this.alert_msg = null;
      this.userid = row.userid
      this.did = row.id;
      this.aid = row.acc_id;
      this.sid = row.strat_id;
      this.massets = row.tradedAssets;
      this.mbroker_server = row.master_server;
      this.master_platform = row.master_platform;

      if (row.acc_type == 'master') {
        this.isFollower = false;
        this.assets = row.tradedAssets;
      }
      if (row.acc_type == 'follower') {
        this.isFollower = true;
        this.assets = row.tradedAssetsMatching;
        this.assetSuffix = row.assetSuffix;
        this.assetPrefix = row.assetPrefix;
        this.tradeComment = row.tradeComment;
        this.assetsPairsToReceiveSignals = row.settings.assetsPairsToReceiveSignals
        if (this.assetsPairsToReceiveSignals && this.assetsPairsToReceiveSignals != 'all') {
          this.allButTheseAssetsPairsReceiveSignal = row.settings.allButTheseAssetsPairsReceiveSignal;
          this.onlyTheseAssetsPairsReceiveSignal = row.settings.onlyTheseAssetsPairsReceiveSignal;
        }
        this.defaultSuffix = !row.assetSuffix && row.assetSuffix != '';
      }

      this.completed = false;
      this.modalassets = true;
    },
    loadNewMasters(resp) {
      this.tableData = [];
      for(var i=0;i < resp.data.length;i++) {
        let s = resp.data[i];
        s['confirmed'] = false;
        s['showingpass'] = false;
        s['public'] = this.$t('notxt');
        if (s.discoverable) {
          s['price'] = (parseFloat(s['price']) * 100).toString() + "%";
          s['public'] = this.$t('yestxt');
        }
        this.tableData.push(s);
      }
      this.sortByCol();
      this.inprogress = false;
    },
    showpass(id, pwd) {
      const el = document.createElement('textarea');
      el.value = pwd;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      if (this.passid !== null && this.passid !== id) {
        const prid = this.tableData.findIndex(t => t.id === this.passid);
        this.tableData[prid].showingpass = false;
      }

      const rid = this.tableData.findIndex(t => t['id'] == id);
      if (rid >= 0) {
        this.passid = rid;
        this.tableData[rid].showingpass = true;
        const removeShowingPass = id => {
          this.tableData[rid].showingpass = false;
          this.passid = null;
        }
        setTimeout(removeShowingPass, timeoutcount);
      }
    },
    sortItNum(a, b) {
      if (this.sortby.order === 'asc') {
        return a[this.colSort] - b[this.colSort];
      }
      return b[this.colSort] - a[this.colSort];
    },
    sortIt(a, b) {
      if (a[this.colSort] < b[this.colSort]) {
        return this.sortby.order === 'asc' ? -1 : 1;
      }
      if (a[this.colSort] > b[this.colSort]) {
        return this.sortby.order === 'asc' ? 1 : -1;
      }
      return 0;
    },
    sortByCol() {
      this.colSort = this.sortby.column;
      if (['userid', 'first_name', 'last_name', 'strat_name', 'location'].includes(this.sortby.column)) {
        this.tableData.sort(this.sortIt);
      } else {
        this.tableData.sort(this.sortItNum);
      }
    },
    failop (error) {
      if (error.data && error.data.msg) {
        error = error.data.msg;
      }
      this.inprogress = false
      this.$toast.error(this.$t(error))
    }
  },
  mounted () {
    this.changeLogsAssets().then(this.loadNewMasters, this.failop);
  },
  created(){
    this.$setTranslatingInProgress()
  },
  beforeDestroy(){
    this.$root.$off("showTranslatingProgress")
  },
}
</script>
<style>
</style>
